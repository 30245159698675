'use client';

import { UilSick } from '@iconscout/react-unicons';

import ErrorPage from '@/components/Pages/Error';

function NotFound() {
  return (
    <ErrorPage
      title="Page not found"
      message="The page you’re looking for doesn’t exist."
      icon={<UilSick className="size-48" />}
    />
  );
}

export default NotFound;
